import React from 'react'

const defaultProps = {
  height: '100',
  width: '100',
}

const IconReport = (props) => {
  const viewBox = `0 0 ${props.height} ${props.width}`
  return (
    <svg
      height={props.width}
      viewBox={viewBox}
      width={props.height}
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M87.499 57.3644C87.499 58.4068 86.654 59.2519 85.6116 59.2519C84.5691 59.2519 83.7241 58.4068 83.7241 57.3644V30.2504C83.7193 26.1101 80.5517 22.5044 76.384 22.4997H23.8249C19.6571 22.5044 16.2797 25.8597 16.275 30V70.2519C16.2797 74.3922 19.8323 77.4953 24 77.5H63.0726C64.1082 77.5 64.9477 78.3395 64.9477 79.3751C64.9477 80.4106 64.1082 81.2501 63.0726 81.2501H24C17.7455 81.2501 12.5 76.4653 12.5 70.2519V30C12.5 23.7866 17.5703 18.7496 23.8249 18.7496H76.384C82.6385 18.7496 87.499 24.037 87.499 30.2504V57.3644ZM87.4472 78.799C88.1843 79.5313 88.1843 80.7185 87.4472 81.4508C86.7101 82.1831 85.515 82.1831 84.7779 81.4508L80.7495 77.4489C79.2576 78.4306 77.4685 79.0022 75.545 79.0022C70.3329 79.0022 66.1077 74.8047 66.1077 69.6269C66.1077 64.449 70.3329 60.2515 75.545 60.2515C80.7571 60.2515 84.9824 64.449 84.9824 69.6269C84.9824 71.5377 84.407 73.3151 83.4188 74.7972L87.4472 78.799ZM81.2074 69.6269C81.2074 70.7496 80.8796 71.8143 80.2715 72.7264L79.6314 73.6865L78.665 74.3223C77.7469 74.9264 76.6751 75.2521 75.545 75.2521C72.4177 75.2521 69.8826 72.7336 69.8826 69.6269C69.8826 66.5201 72.4177 64.0017 75.545 64.0017C78.6723 64.0017 81.2074 66.5201 81.2074 69.6269Z"
        fill="#5A6382"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.3313 36.6667C38.5967 36.6667 38 37.2587 38 37.9891V62.0109C38 62.7263 38.593 63.3333 39.3245 63.3333H60.6755C61.4068 63.3333 62 62.7319 62 61.99L61.9996 43.3333L55.3333 36.6667H39.3313ZM48.6666 43.3333H51.3333V51.3333H48.6666V43.3333ZM48.6666 54H51.3333V56.6667H48.6666V54Z"
        fill="#5A6382"
      />
    </svg>
  )
}

IconReport.defaultProps = defaultProps

export default IconReport
