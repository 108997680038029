import React, { useEffect } from 'react'
import { useMeasure, useLockBodyScroll, useDebounce } from 'react-use'
import {
  Radio,
  Input,
  Table,
  Badge,
  Spin,
  Empty,
  Button,
  Tooltip,
  Divider as VerticalDivider,
} from 'antd'
import { useParams } from 'react-router'
import { useGlobalSearch } from '../hooks/GlobalSearch'
import { requireAuthentication } from '../helpers/authentication'

import { useHistory } from 'react-router-dom'
import {
  useGlobalSearchContext,
  useGlobalSearchDispatch,
} from './GlobalSearchContext'
import { FormattedMessage, injectIntl } from 'react-intl'
import { LocationImage } from '../FunctionalDiagrams/Image'
import CircuitColorIcon from '../FunctionalDiagrams/CircuitColorIcon'
import PinoutContainer from '../containers/PinoutContainer'
import { useComponentDetails } from '../hooks/ComponentDetails'
import Loading from '../components/Loading'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { setVIN, resetVIN } from '../actions/app-actions'
import {
  DtcResults,
  ManualDtcs,
  GenerateEphemeralVehicleTestButton,
} from './ManualDTC'
import { FUNCTIONAL_DIAGRAMS_FLAG } from '../constants'
import Divider from '../components/Divider'
import { RelatedSubsystemsButton } from '../components/RelatedSubsystemsButton'
import { SubsystemsViewTable } from '../components/SubsystemTable'
import { useSearchSubsystems } from '../BlockDiagram/hooks'
import { ConnectorPreview } from '../components/ConnectorPreview/index'
import { useCheckPermission } from '../hooks/Permissions'
import { useFeatureFlags } from '../hooks/FeatureFlags'
import { useMakeModelByVin } from '../hooks/MakeModels'
import { useCircuitDTCs } from '../hooks/CircuitDTCs'

import {
  CircuitDTCsModal,
  CircuitDTCsProvider,
  RelatedDTCsIconButton,
} from '../CircuitDTCs'
import './style.scss'

const SEARCH_RESULT_CATEGORY = {
  component: 'component',
  device: 'device',
  circuit: 'circuit',
  dtc: 'dtc',
}

const Counter = ({ isLoading, results }) => {
  return isLoading ? (
    <Spin />
  ) : (
    <Badge color="green" count={results ? results.length : 0} showZero />
  )
}

const Pinout = ({ vin, componentId }) => {
  const { componentDetails, isLoading } = useComponentDetails({
    vin,
    componentId,
  })
  return isLoading ? (
    <Loading useBackgroundColor={false} />
  ) : (
    <PinoutContainer
      component={componentDetails.component}
      showEmptyPinsToggle={false}
      showEmptyPinsDefault={true}
    />
  )
}

const NoData = ({ description }) => {
  return (
    <div className="empty">
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<div className="empty-description">{description}</div>}
      />
    </div>
  )
}

const LocationOrPinout = ({
  clickHandler,
  removeSelectionHandler,
  onSVGLoad,
}) => {
  const LOCATION = 'location'
  const PINOUT = 'pinout'
  const globalSearchContext = useGlobalSearchContext()
  const globalSearchDispatch = useGlobalSearchDispatch()
  const [loactionOrPinout, setLocationOrPinout] = React.useState(LOCATION)
  const { alias, color, imageName } = globalSearchContext.selection
  const checkUserPermission = useCheckPermission()
  const { isLoading: userPermissionLoading, usersFeatureFlags } =
    useFeatureFlags()

  const checkPermission = () => {
    if (!userPermissionLoading) {
      const featurePermission = usersFeatureFlags.includes(
        FUNCTIONAL_DIAGRAMS_FLAG,
      )
      const userPermission = checkUserPermission('page-functional-diagrams:get')
      return featurePermission && userPermission
    } else {
      return false
    }
  }

  useEffect(() => {
    globalSearchDispatch.handleRelatedSubsystemComponentId({
      relatedSubsystemComponentID: null,
    })
  }, [globalSearchContext.selection.componentId])

  const showSelectedView = () => {
    switch (loactionOrPinout) {
      case LOCATION:
        return globalSearchContext.selection.locationImageName ? (
          <>
            <LocationImage
              fileName={globalSearchContext.selection.locationImageName}
              componentId={globalSearchContext.selection.componentId}
              clickHandler={clickHandler}
              removeSelectionHandler={removeSelectionHandler}
              onSVGLoad={onSVGLoad}
            />
            <div className="global-search-component-overlay">
              <ConnectorPreview
                title={alias}
                colorCode={color}
                fileName={imageName}
              />
            </div>
          </>
        ) : (
          <NoData description="No Image" />
        )
      case PINOUT:
        return (
          <Pinout
            vin={globalSearchContext.vin}
            componentId={globalSearchContext.selection.componentId}
          />
        )
      default:
        return ''
    }
  }

  if (globalSearchContext.selection.componentId === null) {
    return <NoData description="Select Component" />
  }

  return (
    <div className="component-location-or-pinout">
      <div className="component-location-or-pinout-title">
        <FormattedMessage id="globalSearch.component" />:{' '}
        {globalSearchContext.selection.componentId}
      </div>
      <div className="component-location-or-pinout-toggle">
        <Radio.Group
          value={loactionOrPinout}
          buttonStyle="solid"
          size="middle"
          onChange={(event) => setLocationOrPinout(event.target.value)}
        >
          <Radio.Button value={LOCATION}>
            <FormattedMessage id="globalSearch.previewSwitch.componentLocation" />
          </Radio.Button>
          <Radio.Button value={PINOUT}>
            <FormattedMessage id="globalSearch.previewSwitch.pinout" />
          </Radio.Button>
        </Radio.Group>
        {checkPermission() &&
          globalSearchContext.selection.category !== 'inline' && (
            <>
              <VerticalDivider type="vertical" />
              <div className="global-search-related-subsystem-button">
                <RelatedSubsystemsButton
                  showButtonText={true}
                  vin={globalSearchContext.vin}
                  componentId={globalSearchContext.selection.componentId}
                  contextComponentId={
                    globalSearchContext.subsystems.componentId
                  }
                  componentIdDispatch={
                    globalSearchDispatch.handleRelatedSubsystemComponentId
                  }
                />
              </div>
            </>
          )}
      </div>
      <div className="component-location-or-pinout-view">
        {showSelectedView()}
      </div>
    </div>
  )
}

const ResultsTable = ({
  columns,
  results,
  isLoading,
  dataTestId,
  rowKey,
  setSelectedRowKeys,
  selectedRowKeys,
  expandable = false, // https://4x.ant.design/components/table/#expandable
  onChange,
  pageSize,
}) => {
  const [containerRef, { height: containerHeight }] = useMeasure()
  const globalSearchDispatch = useGlobalSearchDispatch()
  const [tableHeight, setTableHeight] = React.useState(0)

  // containerHeight changes its height multiple times during re-render
  // but only the largest (last) value is needed.
  useDebounce(
    () => {
      setTableHeight(containerHeight - 50) // 50 is the height of header.
    },
    1,
    [containerHeight],
  )

  const onSelectDefaultCallback = (
    record,
    selected,
    selectedRows,
    nativeEvent,
  ) => {
    setSelectedRowKeys([rowKey(record)])
    globalSearchDispatch.handleComponentSelection({
      componentId: record.componentId,
      locationImageName: record.locationImageName,
      color: record.color,
      alias: record.alias,
      imageName: record.imageName,
      category: record.category,
    })
  }

  return (
    <div className="global-search-results-table" ref={containerRef}>
      <Table
        data-testid={dataTestId}
        size="middle"
        columns={columns}
        dataSource={results}
        loading={isLoading}
        pagination={
          pageSize
            ? {
                pageSize: pageSize,
                hideOnSinglePage: true,
              }
            : false
        }
        scroll={{
          y: tableHeight,
        }}
        rowKey={rowKey}
        rowSelection={{
          type: 'radio',
          onSelect: onSelectDefaultCallback,
          selectedRowKeys: selectedRowKeys,
        }}
        expandable={expandable}
        onChange={(pagination, filters, sorter, extra) => {
          onChange({ filters })
        }}
      />
    </div>
  )
}

const commonColumns = [
  {
    title: <FormattedMessage id="globalSearch.resultsTable.commonColumns.id" />,
    dataIndex: 'componentId',
    key: 'componentId',
    ellipsis: true,
    width: '20%',
    render: (componentId) => (
      <Tooltip placement="topLeft" title={componentId}>
        {componentId}
      </Tooltip>
    ),
  },
  {
    title: (
      <FormattedMessage id="globalSearch.resultsTable.commonColumns.alias" />
    ),
    dataIndex: 'alias',
    key: 'alias',
    ellipsis: true,
    render: (alias) => (
      <Tooltip placement="topLeft" title={alias}>
        {alias}
      </Tooltip>
    ),
  },
  {
    title: (
      <FormattedMessage id="globalSearch.resultsTable.commonColumns.description" />
    ),
    dataIndex: ['description'],
    key: 'description',
    ellipsis: true,
    width: '25%',
    render: (description) => (
      <Tooltip placement="topLeft" title={description}>
        {description}
      </Tooltip>
    ),
  },
]

const DeviceResults = ({
  results,
  isLoading,
  setSelectedRowKeys,
  selectedRowKeys,
}) => {
  const columns = [...commonColumns]
  const user = useSelector((state) => state.user.data, shallowEqual)

  if (!user.organization_type || user.organization_type !== 'dealer') {
    columns.push({
      title: (
        <FormattedMessage id="globalSearch.resultsTable.commonColumns.station" />
      ),
      dataIndex: ['assembly_station'],
      key: 'station',
    })
  }
  columns[1].filters = undefined
  columns[1].onFilter = undefined

  return (
    <ResultsTable
      columns={columns}
      results={results}
      loading={isLoading}
      dataTestId="device-results"
      rowKey={(record) => record.componentId}
      setSelectedRowKeys={setSelectedRowKeys}
      selectedRowKeys={selectedRowKeys}
    />
  )
}

const CircuitResults = ({
  results,
  isLoading,
  setSelectedRowKeys,
  selectedRowKeys,
  activeFilters,
  setActiveFilters,
}) => {
  const globalSearchContext = useGlobalSearchContext()
  const user = useSelector((state) => state.user.data, shallowEqual)
  const [filters, setFilters] = React.useState([])
  const { makeModel } = useMakeModelByVin({
    vin: globalSearchContext.vin,
  })
  const [circuits, setCircuits] = React.useState([])
  const [relatedDtcs, setRelatedDtcs] = React.useState({})

  const { isLoading: circuitDTCsLoading, dtcs } = useCircuitDTCs({
    makeModelId: makeModel?.id,
    circuits: [...circuits].sort((a, b) => a.localeCompare(b)),
  })

  React.useEffect(() => {
    if (!results) return
    setCircuits([
      ...new Set(results.map((component) => component.circuit.circuit)),
    ])
  }, [results])

  React.useEffect(() => {
    if (!circuits) return
    setFilters(circuits.map((circuit) => ({ text: circuit, value: circuit })))
  }, [circuits])

  React.useEffect(() => {
    if (!dtcs) return
    setRelatedDtcs(
      dtcs.reduce((acc, obj) => {
        const circuit = obj['circuit']
        if (!acc[circuit]) {
          acc[circuit] = []
        }
        acc[circuit].push(obj)
        return acc
      }, {}),
    )
  }, [dtcs])

  const columns = [
    ...commonColumns,
    Table.EXPAND_COLUMN,
    {
      title: (
        <FormattedMessage id="globalSearch.resultsTable.circuitResults.circuit" />
      ),
      dataIndex: ['circuit', 'circuit'],
      key: 'circuit',
      filters,
      onFilter: (value, record) => record.circuit.circuit === value,
      ellipsis: true,
      filteredValue: activeFilters,
    },
  ]

  if (!user.organization_type || user.organization_type !== 'dealer') {
    columns.push({
      title: (
        <FormattedMessage id="globalSearch.resultsTable.commonColumns.station" />
      ),
      dataIndex: ['assembly_station'],
      key: 'station',
      ellipsis: true,
    })
  }

  columns[1].filters = undefined
  columns[1].onFilter = undefined

  columns.push({
    title: (
      <FormattedMessage id="globalSearch.resultsTable.circuitResults.dtcs" />
    ),
    render: (value, record, index) => (
      <CircuitDTCsProvider>
        <RelatedDTCsIconButton
          relatedDtcs={relatedDtcs[record.circuit.circuit]}
          disabled={!(record.circuit.circuit in relatedDtcs)}
          makeModelId={makeModel?.id}
          circuit={record.circuit.circuit}
          loading={circuitDTCsLoading}
        />
        <CircuitDTCsModal />
      </CircuitDTCsProvider>
    ),
    width: '7%',
  })

  const onChangeCallback = ({ filters }) => {
    setActiveFilters(filters.circuit)
  }

  return (
    <ResultsTable
      columns={columns}
      results={results}
      loading={isLoading}
      dataTestId="circuit-results"
      rowKey={(record) => record.id}
      setSelectedRowKeys={setSelectedRowKeys}
      selectedRowKeys={selectedRowKeys}
      expandable={{
        expandedRowRender: (record, index, indent, expanded) => {
          const [color1, color2] = record.circuit.colors.hex_triplets
          return (
            <div className="circuit-details-expand">
              <div>
                <FormattedMessage id="globalSearch.resultsTable.circuitResults.details.description" />
                : {record.circuit.description}
              </div>
              <div>
                <FormattedMessage id="globalSearch.resultsTable.circuitResults.details.color" />
                :{' '}
                {
                  <CircuitColorIcon
                    firstColor={color1}
                    secondColor={color2}
                    className="inline-color-icon"
                  />
                }{' '}
                {record.circuit.colors.letter_code}
              </div>
              <div>
                <FormattedMessage id="globalSearch.resultsTable.circuitResults.details.pin" />
                : {record.pin}
              </div>
              <div>
                <FormattedMessage id="globalSearch.resultsTable.circuitResults.details.harnessDescription" />
                : {record.circuit.harness_description}
              </div>
            </div>
          )
        },
        rowExpandable: (record) => true,
        showExpandColumn: true,
      }}
      onChange={onChangeCallback}
    />
  )
}

const ComponentResults = ({
  results,
  isLoading,
  setSelectedRowKeys,
  selectedRowKeys,
  activeFilters,
  setActiveFilters,
}) => {
  const user = useSelector((state) => state.user.data, shallowEqual)
  const [filters, setFilters] = React.useState([])

  React.useEffect(() => {
    if (!results) return
    setFilters(
      [...new Set(results.map((component) => component.alias))].map(
        (alias) => ({ text: alias, value: alias }),
      ),
    )
  }, [results])

  const columns = [...commonColumns, Table.EXPAND_COLUMN]

  if (!user.organization_type || user.organization_type !== 'dealer') {
    columns.push({
      title: (
        <FormattedMessage id="globalSearch.resultsTable.commonColumns.station" />
      ),
      dataIndex: ['assembly_station'],
      key: 'station',
    })
  }

  columns[1].filters = filters
  columns[1].onFilter = (value, record) => record.alias === value
  columns[1].filteredValue = activeFilters

  const onChangeCallback = ({ filters }) => {
    setActiveFilters(filters.alias)
  }

  return (
    <ResultsTable
      columns={columns}
      results={results}
      loading={isLoading}
      dataTestId="component-results"
      rowKey={(record) => record.componentId}
      setSelectedRowKeys={setSelectedRowKeys}
      selectedRowKeys={selectedRowKeys}
      expandable={{
        expandedRowRender: (record, index, indent, expanded) => {
          return (
            <div className="circuit-details-expand">
              <FormattedMessage id="globalSearch.resultsTable.componentResults.details.device" />
              : {record.deviceId}
            </div>
          )
        },
        rowExpandable: (record) => true,
        showExpandColumn: true,
      }}
      onChange={onChangeCallback}
    />
  )
}

const SVGSelectionTable = ({ componentList, onRowSelect }) => {
  const columns = [...commonColumns]
  const user = useSelector((state) => state.user.data, shallowEqual)

  if (!user.organization_type || user.organization_type !== 'dealer') {
    columns.push({
      title: (
        <FormattedMessage id="globalSearch.resultsTable.commonColumns.station" />
      ),
      dataIndex: ['assembly_station'],
      key: 'station',
    })
  }
  columns[1].filters = undefined
  columns[1].onFilter = undefined

  return (
    <div className="global-search-svg-component-list">
      <Table
        data-testid="svg-components-list"
        size="small"
        columns={columns}
        dataSource={componentList}
        pagination={{
          pageSize: 2,
          hideOnSinglePage: true,
        }}
        rowKey={(record) => record.componentId}
        rowSelection={{
          type: 'radio',
          onSelect: onRowSelect,
          selectedRowKeys: [],
        }}
      />
    </div>
  )
}

function GlobalSearchPage({ intl }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const globalSearchContext = useGlobalSearchContext()
  const globalSearchDispatch = useGlobalSearchDispatch()
  const { vin } = useParams()

  const [componentListSearchString, updateComponentListSearchString] =
    React.useState(null)

  const { results, isLoading } = useGlobalSearch({
    vin: globalSearchContext.vin,
    searchString: globalSearchContext.searchString,
  })

  const { results: componentList, isLoading: componentListLoading } =
    useGlobalSearch({
      vin: vin,
      searchString: componentListSearchString,
    })

  const { searchSubsystems } = useSearchSubsystems({
    vin: globalSearchContext.vin,
    componentId: globalSearchContext.subsystems.componentId,
  })

  const [circuitActiveFilters, setCircuitActiveFilters] = React.useState([])
  const [componentActiveFilters, setComponentActiveFilters] = React.useState([])
  const [resultsByCircuits, setResultsByCircuits] = React.useState([])
  const [resultsByDevice, setResultsByDevice] = React.useState([])
  const [resultsByComponent, setResultsByComponent] = React.useState([])
  const [resultsByDtc, setResultsByDtc] = React.useState([])
  const [searchResultCategory, setSearchResultCategory] = React.useState(
    SEARCH_RESULT_CATEGORY.component,
  )
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([])
  const [searchStr, updateSearchStr] = React.useState(null)
  const [componentListState, updateComponentListState] = React.useState(null)
  const [selectedElementId, updateSelectedElementId] = React.useState(null)
  const [selectionSet, updateSelectionSet] = React.useState(new Set())

  useLockBodyScroll(true)

  React.useEffect(() => {
    removeSelectionHandler()
  }, [selectedRowKeys])

  React.useEffect(() => {
    if (globalSearchContext.subsystems.componentId) {
      removeSelectionHandler()
      if (selectedElementId) {
        document
          .getElementById(selectedElementId)
          .setAttribute('fill-opacity', '0')
        updateSelectedElementId(null)
      }
    }
  }, [globalSearchContext.subsystems.componentId])

  React.useEffect(() => {
    dispatch(setVIN(vin))
    return () => dispatch(resetVIN())
  }, [vin, dispatch])

  React.useEffect(() => {
    if (!results) {
      globalSearchDispatch.handleRelatedSubsystemComponentId({
        relatedSubsystemComponentID: null,
      })
    }
  }, [results])

  React.useEffect(() => {
    if (!results) {
      setResultsByCircuits([])
    } else {
      setResultsByCircuits(
        results
          .filter((result) => result.search_category === 'circuit')
          .map((result) => ({ ...result, id: crypto.randomUUID() })),
      )
    }
  }, [results])

  React.useEffect(() => {
    if (!results) {
      setResultsByDevice([])
    } else {
      setResultsByDevice(
        results.filter((result) => result.search_category === 'device'),
      )
    }
  }, [results])

  React.useEffect(() => {
    if (!results) {
      setResultsByComponent([])
    } else {
      setResultsByComponent(
        results.filter((result) => result.search_category === 'component'),
      )
    }
  }, [results])

  React.useEffect(() => {
    if (!results) {
      setResultsByDtc([])
    } else {
      setResultsByDtc(
        results.filter((result) => result.search_category === 'dtc'),
      )
    }
  }, [results])

  React.useEffect(() => {
    if (componentList && selectionSet.size !== 0) {
      filterComponents(selectionSet)
      updateSelectionSet(new Set())
    }
  }, [componentList])

  const onSVGElementClick = (elements, targetId) => {
    const componentSet = new Set(
      elements.map((element) => element.id.substring(1)),
    )

    if (componentList) {
      filterComponents(componentSet)
    } else {
      updateSelectionSet(componentSet)
    }
    updateSelectedElementId(targetId)
  }

  const filterComponents = (elements) => {
    const list = componentList.filter((component) =>
      elements.has(component.componentId),
    )
    globalSearchDispatch.handleRelatedSubsystemComponentId({
      relatedSubsystemComponentID: null,
    })
    updateComponentListState(list)
  }

  const onSVGTableRowSelect = (record, selected, selectedRows, nativeEvent) => {
    resetState()
    globalSearchDispatch.handleRelatedSubsystemComponentId({
      relatedSubsystemComponentID: null,
    })
    updateSearchStr(record.componentId)
    globalSearchDispatch.handleSearch({
      vin,
      searchString: record.componentId,
    })
  }

  const showSelectedTable = () => {
    switch (searchResultCategory) {
      case 'component':
        return (
          <ComponentResults
            results={resultsByComponent}
            isLoading={isLoading}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRowKeys={selectedRowKeys}
            activeFilters={componentActiveFilters}
            setActiveFilters={setComponentActiveFilters}
          />
        )
      case 'device':
        return (
          <DeviceResults
            results={resultsByDevice}
            isLoading={isLoading}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRowKeys={selectedRowKeys}
          />
        )
      case 'circuit':
        return (
          <CircuitResults
            results={resultsByCircuits}
            isLoading={isLoading}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRowKeys={selectedRowKeys}
            activeFilters={circuitActiveFilters}
            setActiveFilters={setCircuitActiveFilters}
          />
        )
      case 'dtc':
        return <DtcResults results={resultsByDtc} isLoading={isLoading} />
      default:
        return ''
    }
  }

  const onSVGLoad = (idArray) => {
    const idString = idArray
      .map((element) => element.substring(1))
      .toString(',')
    updateComponentListSearchString(idString)
  }

  const removeSelectionHandler = () => {
    updateComponentListState(null)
    updateSelectedElementId(null)
  }

  const showLeftPanelContent = () => {
    switch (searchResultCategory) {
      case 'component':
      case 'device':
      case 'circuit':
        return (
          <>
            <h3>
              <FormattedMessage id="globalSearch.preview" />
            </h3>
            <Divider />
            <div className="location-pinout-container">
              <LocationOrPinout
                clickHandler={onSVGElementClick}
                removeSelectionHandler={removeSelectionHandler}
                onSVGLoad={onSVGLoad}
              />
              {searchSubsystems && <RelatedSubsystemTable />}
              {componentListState && (
                <SVGSelectionTable
                  componentList={componentListState}
                  onRowSelect={onSVGTableRowSelect}
                />
              )}
            </div>
          </>
        )
      case 'dtc':
        return (
          <>
            <div>
              <h3>
                <FormattedMessage id="globalSearch.manualDtc.title" />
              </h3>
              <Divider />
              <ManualDtcs />
            </div>
          </>
        )
      default:
        return ''
    }
  }

  const RelatedSubsystemTable = () => {
    const globalSearchContext = useGlobalSearchContext()

    const selectSubsystem = ({ record }) => {
      history.push({
        pathname: `/functional-diagrams/vin/${globalSearchContext.vin}`,
        search: `?subsystem=${record.id}`,
      })
    }

    return (
      <div className="global-search-left-panel-content-subsystems">
        <SubsystemsViewTable
          subsystems={searchSubsystems}
          pageSize={2}
          susbsystemCallback={selectSubsystem}
        />
      </div>
    )
  }

  const onTextChangeCallback = (event) => {
    updateSearchStr(event.target.value)
  }

  const onTextSearchCallback = (event) => {
    resetState()
    updateComponentListState(null)
    if (!event || event.length == 0) {
      updateSearchStr(null)
      globalSearchDispatch.handleSearch({
        vin,
        searchString: null,
      })
    } else {
      globalSearchDispatch.handleSearch({
        vin,
        searchString: searchStr,
      })
    }
  }

  const resetState = () => {
    updateComponentListSearchString(null)
    updateSelectedElementId(null)
    setSelectedRowKeys([])
    setCircuitActiveFilters([])
    setComponentActiveFilters([])
  }

  return (
    <div id="global-search-page">
      <div className="global-search-container">
        <div className="global-search-left-panel">{showLeftPanelContent()}</div>
        <div className="global-search-center-panel"></div>
        <div className="global-search-right-panel">
          <h3>
            <FormattedMessage id="globalSearch.searchInVehicle" />
          </h3>
          <Divider />
          <div className="search-panel">
            <div className="search-input">
              <Input.Group>
                <Input.Search
                  data-testid="search-string"
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({
                    id: 'globalSearch.inputSearchTextPlaceholder',
                  })}
                  allowClear
                  enterButton={intl.formatMessage({
                    id: 'globalSearch.searchButton',
                  })}
                  loading={isLoading}
                  onSearch={onTextSearchCallback}
                  onChange={onTextChangeCallback}
                  value={searchStr}
                />
              </Input.Group>
            </div>
            <div className="category-toggle">
              <Radio.Group
                value={searchResultCategory}
                buttonStyle="solid"
                size="middle"
                onChange={(event) =>
                  setSearchResultCategory(event.target.value)
                }
              >
                <Radio.Button
                  data-testid="results-switch-components"
                  value={SEARCH_RESULT_CATEGORY.component}
                >
                  <FormattedMessage id="globalSearch.resultsSwitch.components" />{' '}
                  <Counter isLoading={isLoading} results={resultsByComponent} />
                </Radio.Button>
                <Radio.Button
                  data-testid="results-switch-devices"
                  value={SEARCH_RESULT_CATEGORY.device}
                >
                  <FormattedMessage id="globalSearch.resultsSwitch.devices" />{' '}
                  <Counter isLoading={isLoading} results={resultsByDevice} />
                </Radio.Button>
                <Radio.Button
                  data-testid="results-switch-circuits"
                  value={SEARCH_RESULT_CATEGORY.circuit}
                >
                  <FormattedMessage id="globalSearch.resultsSwitch.circuits" />{' '}
                  <Counter isLoading={isLoading} results={resultsByCircuits} />
                </Radio.Button>
                <Radio.Button
                  data-testid="results-switch-dtcs"
                  value={SEARCH_RESULT_CATEGORY.dtc}
                >
                  <FormattedMessage id="globalSearch.resultsSwitch.dtc" />{' '}
                  <Counter isLoading={isLoading} results={resultsByDtc} />
                </Radio.Button>
              </Radio.Group>
            </div>
            <div className="search-results">{showSelectedTable()}</div>
          </div>
        </div>
      </div>
      <div className="global-search-bottom-panel">
        {searchResultCategory === 'dtc' && (
          <>
            <GenerateEphemeralVehicleTestButton />
            <Button
              onClick={() => {
                globalSearchDispatch.handleDtcsSelectionReset()
              }}
              ghost
            >
              <FormattedMessage id="globalSearch.clearButton" />
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default requireAuthentication(injectIntl(GlobalSearchPage))
