import { useQuery } from 'react-query'
import { apiGetCircuitDTCs } from '../api/circuit-dtcs-api'

const CIRCUIT_DTCS_KEY = 'circuit-dtcs'

export const useCircuitDTCs = ({ makeModelId, circuits }) => {
  const {
    status,
    data: dtcs,
    error,
    isFetching,
    isLoading,
    isError,
    isIdle,
    isSuccess,
  } = useQuery(
    [CIRCUIT_DTCS_KEY, makeModelId, ...circuits],
    async () => {
      const result = await apiGetCircuitDTCs({ makeModelId, circuits })
      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!makeModelId && !!circuits.length,
    },
  )

  return {
    status,
    dtcs,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    isIdle,
  }
}
