import { useEffect, useState } from 'react'
import { Select } from 'antd'
import { useGetIssues } from '../hooks/Issues'

const emptyProblemDesc = {
  what: null,
  when: null,
  where: null,
  who: null,
  which: null,
}

const getProblemDesc = (issue) => {
  if (!!issue && !!issue.problem_description) {
    return issue.problem_description
  }
  return structuredClone(emptyProblemDesc)
}

// This is the "what", "when", "where", "who", "which" component
export const ProblemDescription = ({ issue, setIssue, readOnly, intl }) => {
  // options are the list of descriptiosn to choose from
  const [options, setOptions] = useState({
    what: [],
    when: [],
    where: [],
    who: [],
    which: [],
  })

  const [problemDesc, setProblemDesc] = useState(getProblemDesc(issue))
  const { issues } = useGetIssues()

  useEffect(() => {
    if (!issues) {
      return
    }
    const opts = {
      what: [],
      when: [],
      where: [],
      who: [],
      which: [],
    }
    issues.forEach((iss) => {
      const pDesc = iss['problem_description']
      if (pDesc) {
        Object.keys(opts).forEach((key) => {
          opts[key].push(pDesc[key])
        })
      }
    })
    // dedupe and give label/value attributes expected by select
    Object.keys(opts).forEach((key) => {
      const allOptions = [...new Set(opts[key])]
      opts[key] = allOptions
        .filter((opt) => opt)
        .map((opt) => {
          return {
            label: opt,
            value: opt,
          }
        })
    })
    setOptions(opts)
  }, [issues])

  useEffect(() => {
    if (!issue) {
      return
    }
    setProblemDesc(getProblemDesc(issue))
  }, [issue])

  const updateIssueProblemDesc = (key, value) => {
    const newProblemDesc = structuredClone(problemDesc)
    const keyVal = value[0]
    newProblemDesc[key] = keyVal
    setProblemDesc(newProblemDesc)
    let newIssue = { ...issue, ...{ problem_description: newProblemDesc } }
    setIssue(newIssue)
  }

  return (
    <div id="issue-problem-description">
      {Object.keys(options).map((key) => {
        return (
          <div className="input-and-label" key={`problem-description-${key}`}>
            <span className="input-label">
              {intl.formatMessage({
                id: `incidentMgmt.problemDescription.${key}`,
                defaultMessage: key,
              })}
              :
            </span>
            <Select
              mode="tags"
              placeholder={
                readOnly
                  ? ''
                  : intl.formatMessage({
                      id: 'incidentMgmt.searchPlaceholder',
                      defaultMessage: 'Enter or Select Similar',
                    })
              }
              options={options[key]}
              onChange={(value) => updateIssueProblemDesc(key, value)}
              value={problemDesc[key] ? [problemDesc[key]] : []}
              maxCount="1"
              disabled={readOnly}
            />
          </div>
        )
      })}
    </div>
  )
}
