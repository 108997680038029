import React from 'react'
import { Modal, Tag, Skeleton, Table } from 'antd'
import { useCircuitDTCs } from '../hooks/CircuitDTCs'
import {
  useCircuitDTCsContext,
  useCircuitDTCsDispatch,
} from './CircuitDTCsContext'
import { FormattedMessage } from 'react-intl'

export const CircuitDTCsModal = () => {
  const context = useCircuitDTCsContext()
  const dispatch = useCircuitDTCsDispatch()

  const { isLoading, dtcs } = useCircuitDTCs({
    makeModelId: context.makeModelId,
    circuits: [context.circuit],
  })

  return (
    <Modal
      data-testid="related-dtcs-modal"
      open={context.makeModelId !== null && context.circuit != null}
      onOk={() => dispatch.reset()}
      onCancel={() => dispatch.reset()}
      cancelButtonProps={{ hidden: true }}
      title={`DTCs related to ${context.circuit}`}
      width={800}
    >
      <DTCTable dtcs={dtcs} isLoading={isLoading} />
    </Modal>
  )
}

const DTCTable = ({ dtcs, isLoading }) => {
  const columns = [
    {
      title: <FormattedMessage id="circuitDTCs.dtc" />,
      dataIndex: 'dtc',
      width: 150,
    },
    {
      title: <FormattedMessage id="circuitDTCs.acronym" />,
      dataIndex: 'acronym',
      width: 150,
    },
    {
      title: <FormattedMessage id="circuitDTCs.shortDescription" />,
      dataIndex: 'dtc_short_description',
    },
  ]
  return (
    <Table
      loading={isLoading}
      dataSource={dtcs}
      columns={columns}
      rowKey={(record) =>
        `${record.dtc}-${record.acronym}-${record.makeModelId}`
      }
      data-testid="related-dtcs-results-table"
      pagination={false}
      scroll={{
        y: 300,
      }}
    />
  )
}
