import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { navigateToIncidentMgmtPage } from '../IncidentMgmt/IncidentMgmt'
import { linkProblemLogToIssue } from '../api/issues'
import { IssuesCloseOutlined, ExceptionOutlined } from '@ant-design/icons'
import { isEmpty } from 'lodash'

export const IssueStatus = ({ fault }) => {
  const hasMoreCases = () => {
    // if any of the problems are missing an attached issue
    if (!!fault.problems) {
      return fault.problems.some((prob) => {
        return !prob.attachedIssue
      })
    }
    return false
  }

  const isReviewed = () => {
    return !!fault.attachedIssue && !hasMoreCases()
  }
  if (isReviewed()) {
    return <span className="issue-status">Reviewed</span>
  }
  if (fault.attachedIssue && hasMoreCases()) {
    return <span className="issue-status">More Cases</span>
  }
  return <span className="issue-status">Pending</span>
}

export const IssueButton = ({ fault }) => {
  if (fault.attachedIssue) {
    return ''
  }
  let msg = '+'
  if (fault && fault.relatedIssues && fault.relatedIssues.length > 0) {
    msg = `${fault.relatedIssues.length}`
  }
  return (
    <Button
      class="tst-msg"
      color="primary"
      variant="outlined"
      onClick={() => {
        navigateToIncidentMgmtPage(fault)
      }}
    >
      <IssuesCloseOutlined />
      {msg}
    </Button>
  )
}

export const AddCaseButton = ({ fault, issue, setFault, parentFault }) => {
  const wasFaultAttachedByButton = () => {
    return (
      (!isEmpty(fault.buttonAddedIssues) &&
        fault.buttonAddedIssues.includes(fault.id)) ||
      fault.wasAttachedByButton
    )
  }

  const attachFaultToIssue = () => {
    linkProblemLogToIssue(issue.id, fault.id).then(() => {
      const newParentFault = structuredClone(parentFault)
      if (!newParentFault.buttonAddedIssues) {
        newParentFault.buttonAddedIssues = [fault.id]
      } else {
        newParentFault.buttonAddedIssues.push(fault.id)
      }
      newParentFault.problems.forEach((prob, idx) => {
        if (prob.id === fault.id) {
          newParentFault.problems[idx].attachedIssue = issue
          newParentFault.problems[idx].wasAttachedByButton = true
        }
      })
      setFault(newParentFault)
    })
  }

  if ((!wasFaultAttachedByButton() && fault.attachedIssue) || !issue) {
    return ''
  }

  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={attachFaultToIssue}
      disabled={wasFaultAttachedByButton()}
    >
      <ExceptionOutlined /> {wasFaultAttachedByButton() ? '√' : ''}
    </Button>
  )
}
