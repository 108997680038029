import React from 'react'
import { Button, Spin, Badge, Flex, ConfigProvider, Tooltip } from 'antd'
import { BranchesOutlined, WarningOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import {
  useCircuitDTCsDispatch,
  useCircuitDTCsContext,
} from './CircuitDTCsContext'
import { useCircuitDTCs } from '../hooks/CircuitDTCs'

const Counter = ({ isLoading, results }) => {
  return isLoading ? (
    <Spin />
  ) : (
    <Badge
      color={results?.length > 0 ? 'green' : 'gray'}
      count={results ? results.length : 0}
    />
  )
}

export const CircuitDTCsButton = ({ makeModelId, circuit, loading }) => {
  const dispatch = useCircuitDTCsDispatch()
  const { isLoading: circuitDTCsLoading, dtcs } = useCircuitDTCs({
    makeModelId: makeModelId,
    circuits: [circuit],
  })

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorTextDisabled: 'gray',
          },
        }}
      >
        <Button
          loading={loading || circuitDTCsLoading}
          onClick={() =>
            dispatch.handleCircuitProvided({ makeModelId, circuit })
          }
          type="default"
          variant="outlined"
          ghost
          disabled={dtcs?.length == 0}
        >
          <Flex gap="small">
            <FormattedMessage id="circuitDTCs.name" />
            <Counter isLoading={circuitDTCsLoading} results={dtcs} />
          </Flex>
        </Button>
      </ConfigProvider>
    </>
  )
}

export const RelatedDTCsIconButton = ({
  makeModelId,
  circuit,
  loading,
  disabled,
  relatedDtcs,
}) => {
  const dispatch = useCircuitDTCsDispatch()

  return (
    <Tooltip
      placement="topLeft"
      title={
        disabled ? (
          <FormattedMessage
            id="circuitDTCs.noRelatedDtcs"
            values={{
              circuit,
            }}
          />
        ) : (
          <FormattedMessage
            id="circuitDTCs.relatedDtcs"
            values={{
              circuit,
              dtcsCount: relatedDtcs.length,
            }}
          />
        )
      }
    >
      <Button
        data-testid="related-dtcs-icon-button"
        loading={loading}
        disabled={disabled}
        icon={<WarningOutlined />}
        onClick={() =>
          dispatch.handleCircuitProvided({
            makeModelId: makeModelId,
            circuit: circuit,
          })
        }
      />
    </Tooltip>
  )
}
