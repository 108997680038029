import { createContext, useContext } from 'react'
import { useImmerReducer } from 'use-immer'

const CircuitDTCsContext = createContext(null)
const CircuitDTCsDispatch = createContext(null)

export function CircuitDTCsProvider({ children }) {
  const [state, dispatch] = useImmerReducer(reducer, initial)

  return (
    <CircuitDTCsContext.Provider value={state}>
      <CircuitDTCsDispatch.Provider value={dispatch}>
        {children}
      </CircuitDTCsDispatch.Provider>
    </CircuitDTCsContext.Provider>
  )
}

export function useCircuitDTCsContext() {
  return useContext(CircuitDTCsContext)
}

export function useCircuitDTCsDispatch() {
  const dispatch = useContext(CircuitDTCsDispatch)

  const reset = () => dispatch({ type: 'reset' })

  const handleCircuitProvided = ({ makeModelId, circuit }) => {
    dispatch({
      type: 'circuit_provided',
      makeModelId,
      circuit,
    })
  }

  return {
    handleCircuitProvided,
    reset,
  }
}

function reducer(draft, action) {
  switch (action.type) {
    case 'reset': {
      draft.makeModelId = initial.makeModelId
      draft.circuit = initial.circuit
      break
    }
    case 'circuit_provided': {
      draft.makeModelId = action.makeModelId
      draft.circuit = action.circuit
      break
    }

    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

const initial = {
  circuit: null,
  makeModelId: null,
}
