import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import Measure from 'react-measure'
import Modal from 'react-modal'
import { injectIntl } from 'react-intl'

import TabContent from './Navigation/TabContent'
import TabPane from './Navigation/TabPane'
import Loading from './Loading'
import PinDetails from './PinDetails'
import FusesAndRelays from './FusesAndRelays'
import Topology from './SvgViews/Topology'
import PinDestinationGraphContainer from '../containers/PinDestinationGraphContainer'
import {
  HARNESS_VIEW,
  VEHICLE_VIEW,
  FUSES_AND_RELAYS_VIEW,
  PINOUT_NAMESPACE,
} from '../constants'
import NavigationTabs from './Navigation/NavigationTabs'
import { getElementName } from '../helpers/element'
import { ZoomInControl, ZoomOutControl, ZoomResetControl } from './ZoomControls'

const propTypes = {
  selectedElement: PropTypes.object,
  zoomFactor: PropTypes.number,
}

const defaultProps = {
  selectedElement: null,
  zoomFactor: 0.25,
}

// This matches the Sass variable in _modals.scss
// Perhaps one day these variables will all be in one place
// P.S. - TODO remove absolute positioning
const modalRowMargin = 20

function Loadable({ isLoading, children }) {
  if (isLoading) {
    const inlineStyle = { height: 100 }
    return (
      <div style={inlineStyle}>
        <Loading useBackgroundColor={false} />
      </div>
    )
  }
  return children
}

function ModalContainer(props) {
  const {
    isLoading,
    onCloseModal,
    selectedElement,
    onShowConnectorModal,
    intl,
    pin,
    vin,
  } = props
  const [headingDimensions, setHeadingDimensions] = useState({
    width: -1,
    height: -1,
  })

  const style = {
    position: 'absolute',
    top: headingDimensions.height + modalRowMargin,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto',
  }

  return (
    <div className="container">
      <div className="left-column">
        <Measure
          bounds
          onResize={(contentRect) => setHeadingDimensions(contentRect.bounds)}
        >
          {({ measureRef }) => (
            <div ref={measureRef} className="row">
              <div className="back-rnd-button" onClick={onCloseModal} />
            </div>
          )}
        </Measure>
        <div className="row" style={style}>
          <Loadable isLoading={isLoading}>
            <PinDetails
              selectedElement={selectedElement}
              showConnectorModal={onShowConnectorModal}
            />
          </Loadable>
        </div>
      </div>
      <div className="right-column gradient-container">
        <div className="view-tabs">
          <NavigationTabs namespace={PINOUT_NAMESPACE} />
        </div>
        <div className="gradient-top" />
        <TabContent>
          <TabPane
            id={HARNESS_VIEW}
            position={0}
            namespace={PINOUT_NAMESPACE}
            canBeDeactivated={false}
          >
            <Loadable isLoading={isLoading}>
              <PinDestinationGraphContainer
                zoomInControl={ZoomInControl}
                zoomOutControl={ZoomOutControl}
                zoomResetControl={ZoomResetControl}
              />
            </Loadable>
          </TabPane>

          <TabPane id={VEHICLE_VIEW} position={1} namespace={PINOUT_NAMESPACE}>
            <Loadable isLoading={isLoading}>
              <Topology component={selectedElement} />
            </Loadable>
          </TabPane>

          <TabPane
            id={FUSES_AND_RELAYS_VIEW}
            text={intl.formatMessage({
              id: 'tracer.fusesAndRelaysView.tabName',
            })}
            position={2}
            namespace={PINOUT_NAMESPACE}
          >
            <Loadable isLoading={isLoading}>
              <FusesAndRelays
                componentId={selectedElement.id}
                pin={pin}
                vin={vin}
                connectorName={getElementName(selectedElement)}
              />
            </Loadable>
          </TabPane>
        </TabContent>
        <div className="gradient-bottom" />
      </div>
    </div>
  )
}

class PinDestination extends Component {
  constructor(props) {
    super(props)
    this.closeModal = this.closeModal.bind(this)
  }

  closeModal() {
    this.props.onClose()
  }

  render() {
    const {
      selectedElement,
      pinDestination,
      pin,
      vin,
      isOpen,
      intl,
      showConnectorModal,
    } = this.props
    const pinDestinationIsEmpty = isEmpty(pinDestination)

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.closeModal}
        className="modal-pin-destination"
        overlayClassName="modal-overlay"
        contentLabel="Modal"
      >
        <ModalContainer
          isLoading={pinDestinationIsEmpty}
          selectedElement={selectedElement}
          onCloseModal={this.closeModal}
          onShowConnectorModal={showConnectorModal}
          pin={pin}
          vin={vin}
          intl={intl}
        />
      </Modal>
    )
  }
}

PinDestination.defaultProps = defaultProps
PinDestination.propTypes = propTypes

export default injectIntl(PinDestination)
