import { injectIntl } from '../../node_modules/react-intl/index'
import { requireAuthentication } from '../helpers/authentication'
import { useState } from 'react'
import { useUserPlant } from '../hooks/User'
import { IssueEditForm } from './IssueEditForm'
import { IssueSummary } from './IssueSummary'

import './styles.scss'

const getFaultIdentifier = (fault) => {
  let identifier = null
  if (fault['type'] == 'component') {
    identifier = fault['component_id']
  } else if (fault['type'] == 'harness') {
    identifier = fault['harness']
  } else if (fault['type'] == 'device') {
    identifier = fault['device_name']
  }
  return identifier
}

const storageKey = 'incident-mgmt-faults'

// the fault will be base64/url encoded as a query param
// for use with the "Add Issue" and "n Issues" button
export const navigateToIncidentMgmtPage = (fault) => {
  const faultStr = JSON.stringify(fault)
  localStorage.setItem(storageKey, faultStr)
  const url = `${window.location.origin}/incidents?fault=${fault.id}`
  window.open(url, '_self')
}

const isFaultInUrl = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return !!urlParams.get('fault')
}

const getFaultFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search)
  let fault = null
  const faultId = urlParams.get('fault')
  if (faultId) {
    const faultString = localStorage.getItem(storageKey)
    fault = JSON.parse(faultString)
  }
  return fault
}

const emptyIssue = {
  component_id: null, // ID of component, harness, or device
  fault_type: null,
  fault_short_code: null,
  plant_id: null,
  problem_log_id: null, // id from problem_logs.id
  description: null,
  problem_description: {
    what: null,
    when: null,
    where: null,
    who: null,
    which: null,
  },
  possible_root_causes: {
    man: null,
    machine: null,
    method: null,
    material: null,
    design: null,
  },
  direct_root_causes: {
    five_whys: [null, null, null, null, null],
    direct_root_cause: null,
    detection_root_cause: null,
    systemic_root_cause: null,
  },
  actions: [
    {
      assignee: null,
      type: null,
      description: null,
      start_date: null,
      due_date: null,
      close_date: null,
      status: 'open',
    },
  ],
  status: 'open',
}

// Gets the issue we're editing based on the passed-in fault
const getIssue = (fault, plant_id) => {
  if (!!fault) {
    if (!!fault.attachedIssue) {
      // the fault already has a linked issue
      return fault.attachedIssue
    }
    // We need to create the issue with the fault info added
    const issue = structuredClone(emptyIssue)
    issue['fault_type'] = fault['problem_code'] //yeah this is a bit weird
    const identifier = getFaultIdentifier(fault)
    issue['component_id'] = identifier
    issue['plant_id'] = plant_id
    issue['problem_log_id'] = fault['id']
    issue['fault_short_code'] = fault['short_code']

    let desc_prfx = ''
    if (fault.reason) {
      desc_prfx = fault.reason
    }

    let desc_sfx = ''

    if (!!fault.component_description) {
      desc_sfx = fault.component_description
    } else if (fault.device_name) {
      desc_sfx = fault.device_name
    } else if (fault.description) {
      desc_sfx = fault.description
    }
    issue['description'] = `${desc_prfx} - ${desc_sfx}`
    return issue
  }
  return null
}

// Gets the possibly related issues to the fault
const getPotentialIssues = (fault) => {
  if (fault) {
    return fault['relatedIssues']
  }
  return null
}

// this page will be opened with a base64-encoded fault as an
// optional "fault" url query param - if it exists we're here from an
// "add issue" or "n issues" button, if not we're here from the home page
function IncidentMgmtPage({ intl }) {
  const [userPlant] = useUserPlant()
  // if fault is null we're just here from the main page,
  // if it's populated we're here from an add/edit issue button
  const [faultInUrl] = useState(isFaultInUrl())
  const [fault, setFault] = useState(getFaultFromURL())
  // if issue is null we are not editing an issue yet but either
  // potentially adding a case to an issue or just viewing from the
  // main page. If issue is populated we're adding/editing it
  const [issue, setIssue] = useState(getIssue(fault, userPlant))
  // potentialIssues are all of the ones that the fault may be linked
  // to where we might add a case
  const [potentialIssues] = useState(getPotentialIssues(fault))

  const isEditingIssue = () => {
    return faultInUrl && (!potentialIssues || potentialIssues.length === 0)
  }

  const isViewingIssue = () => {
    return !faultInUrl || (!!potentialIssues && potentialIssues.length > 0)
  }

  return (
    <div id="incident-mgmt-page">
      <div className="left">
        {isEditingIssue() && ( // we want to be able to edit
          <IssueEditForm
            issue={issue}
            setIssue={setIssue}
            fault={fault}
            intl={intl}
          />
        )}
        {isViewingIssue() && ( // we just want to see the details
          <IssueEditForm
            fault={fault}
            showFaultDetails={true}
            issue={issue}
            setIssue={setIssue}
            orientation="vertical"
            readOnly={true}
            intl={intl}
          />
        )}
      </div>
      <div className="right">
        <IssueSummary
          fault={fault}
          issue={issue}
          intl={intl}
          setIssue={setIssue}
          potentialIssues={potentialIssues}
          setFault={setFault}
          showList={isViewingIssue()}
        />
      </div>
    </div>
  )
}

export default requireAuthentication(injectIntl(IncidentMgmtPage))
